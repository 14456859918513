import './bootstrap';

import * as bootstrap from 'bootstrap'


// Espera hasta que se cargue completamente el DOM
document.addEventListener('DOMContentLoaded', function() {
    // Verifica si el elemento #content existe
    const contentTextarea = document.querySelector('textarea');

    if (contentTextarea) {
      ClassicEditor
        .create(contentTextarea)
        .catch(error => {
          console.error(error);
        });
    } else {
      console.error("El elemento #content no fue encontrado en el DOM.");
    }
  });
